export const sendToEmail = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const nickname = document.querySelector("#nickname").value;
  const email = document.querySelector("#email").value;
  const message = document.querySelector("#message").value;

  const form = document.querySelector(".inner-contact form");

  form.innerHTML = `<div class="loadingio-spinner-eclipse-wtsg7y42hh"><div class="ldio-p52qzuz175t">
  <div></div>
  </div></div>
  <style type="text/css">
  @keyframes ldio-p52qzuz175t {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
  }
  .ldio-p52qzuz175t div {
    position: absolute;
    animation: ldio-p52qzuz175t 0.38s linear infinite;
    width: 109.60000000000001px;
    height: 109.60000000000001px;
    top: 13.700000000000001px;
    left: 13.700000000000001px;
    border-radius: 50%;
    box-shadow: 0 5.48px 0 0 #ffffff;
    transform-origin: 54.800000000000004px 57.540000000000006px;
  }
  .loadingio-spinner-eclipse-wtsg7y42hh {
    width: 137px;
    height: 137px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-p52qzuz175t {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-p52qzuz175t div { box-sizing: content-box; }
  /* generated by https://loading.io/ */
  </style>`;

  fetch(
    `https://jordanmorand.dev/mail/send.php?nickname=${nickname}&email=${email}&message=${message}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      form.innerHTML = `<h3 style="    color: #fff;
      font-size: 40px;
      margin-top: 60px;">Thank you for your message</h3>`;
    })
    .catch((error) => console.log("error", error));
};
