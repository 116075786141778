import { getProjects } from "./fetch/projects.js";
import { sendToEmail } from "./fetch/email.js";

const menu = document.querySelector("#buttonMenu");
const sidebar = document.querySelector("#wrapperMenu");
const linkMenu = document.querySelectorAll(".menu-item");
const slides = document.querySelectorAll(".slide");
const projects = document.querySelectorAll(".item-project");
const wrapperProject = document.querySelector("#wrapperProjects");
const menuProjects = document.querySelectorAll(".menu-item-projects");
const innerProjects = document.querySelectorAll(".inner-item-project");
const canvaThreejs = document.getElementsByTagName("canvas")[0];
const listMenu = document.querySelectorAll(".menu-right .menu-item");

const menuClose = document.querySelector("#menuClose");



const handleClickForm = document.querySelector("#handleSendToEmail");

let accordions = document.querySelectorAll(".accordion");

if(handleClickForm){
  handleClickForm.addEventListener("click", (event) => {
    event.preventDefault();
    // const name = document.querySelector("#name").value;
    // const email = document.querySelector("#email").value;
    // const message = document.querySelector("#message").value;
    // const data = {
    //   name,
    //   email,
    //   message,
    // };
    sendToEmail();
  });
}


linkMenu.forEach((link) => {
  // link.addEventListener('mouseover', (event) => {
  // })
  link.addEventListener("click", (event) => {
    event.preventDefault();
    linkMenu.forEach((link) => {
      link.classList.remove("active");
    });
    link.classList.add("active");

    const slideHref = event.currentTarget.getAttribute("data-href");
    if(slideHref == 'home'){
      window.location.href = "/";
    }else{

      window.location.href = slideHref+'.html';
    }
    if (slideHref == "close") {
      // sidebar.classList.toggle("active");
    } else {
      // sidebar.classList.add("active");
      slides.forEach((slide) => {
        slide.classList.remove("active");
        if (slide.classList.contains(slideHref)) {
          slide.classList.add("active");
        }
      });
    }
  });
});

projects.forEach((project) => {
  project.addEventListener("click", (event) => {
    // event.preventDefault();
    const projectHref = event.currentTarget.getAttribute("data-href");
    projects.forEach((project) => {
      project.classList.remove("active");
      if (project.classList.contains(projectHref)) {
        project.classList.add("active");
        sidebar.classList.toggle("active");
        wrapperProject.classList.add("active");
        //Add class active to menuProjects
        menuProjects.forEach((link) => {
          projectHref == link.getAttribute("data-href")
            ? link.classList.add("active")
            : link.classList.remove("active");
        });
        innerProjects.forEach((canva) => {
          projectHref == canva.getAttribute("data-project")
            ? canva.classList.add("active")
            : canva.classList.remove("active");
        });
      }
    });
  });
});

menuProjects.forEach((link) => {
  link.addEventListener("click", (event) => {
    event.preventDefault();
    const projectHref = event.currentTarget.getAttribute("data-href");
    menuProjects.forEach((link) => {
      link.classList.remove("active");
    });
    if (projectHref == "close") {
      window.location.href = "/";
      // sidebar.classList.toggle('active');
    } else {
      link.classList.add("active");
      innerProjects.forEach((canva) => {
        projectHref == canva.getAttribute("data-project")
          ? canva.classList.add("active")
          : canva.classList.remove("active");
      });
    }
  });
});

const projectsData = getProjects().then((data) => {
  data.forEach((project) => {

    const projectItem = document.createElement("div");
    projectItem.classList.add("item-project");
    projectItem.setAttribute("data-href", project.categorie);

    //Create Accordion inner projectItem
    let currentAccordion = '';
    let count = 0;
    let activeFirstAccordion = '';
    project.accordion.forEach((item) => {
      activeFirstAccordion = (count == 0) ? 'active' : '';
      currentAccordion +=`<div class="item-accordion ${activeFirstAccordion}">
        <h3 onClick="handleClickAccordion(this)">${item.title}</h3>
        <div class="content">
          <div class="inner-content">
            ${item.description.html}
          </div>
        </div>
      </div>`
      count++;
    });

    //Create projectItem
    projectItem.innerHTML = `<div class="row">
      <div class="col-3">
        <div class="accordion">
        ${currentAccordion}
        </div>
      </div>
      <div class="col-9">
        <div class="hovered"><a href="https://${project.url}" target="_blank">Voir le site</a></div>
        <img src="${project.featuredImage.url}" alt="Website" class="img-fluid">
      </div>
    </div>`;
    
    innerProjects.forEach((cat) => {
      if (cat.getAttribute("data-project")== projectItem.getAttribute("data-href")) {
        cat.appendChild(projectItem);
      }
    });
  });
});


window.handleClickAccordion = function(event){
  let items = event.parentNode.parentNode.querySelectorAll(".item-accordion");
  items.forEach((item) => {
    item.classList.remove("active");
  });
  event.parentNode.classList.add("active");
}

const popups = document.querySelectorAll(".item-element-popup");
const linksHeader = document.querySelectorAll(".menu-right .menu-item");
window.rotationDisplayPopup = function(current){
  popups.forEach((popup) => {
    const currentPopup = popup.getAttribute("data-href");
    (currentPopup == current && currentPopup != 'hidden') ? popup.classList.add("active") : popup.classList.remove("active");
  });
  linksHeader.forEach((link) => {
    const currentLink = link.getAttribute("data-hover");
    (currentLink == current && currentLink != 'hidden') ? link.classList.add("active","glow")  : link.classList.remove("active","glow") ;
  });
}


document.addEventListener('mousedown', function(e) {
  e = e || window.event;
  var target = e.target || e.srcElement,
      text = target.textContent || target.innerText;   

  //if target is not canvas
  if (target.tagName == 'CANVAS') {
    sidebar.classList.remove("active");
  }
}, false);