export const getProjects = async() =>{
    const endpoint = "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clgdsr0s905tn01uj8e659pjq/master";
    const headers = {
        "content-type": "application/json"
    };
    const graphqlQuery = {
        "operationName": "Projects",
        "query": `query Projects {
            projects(orderBy: nameProject_ASC, last: 100) {
              id
              categorie
              url
              nameProject
                  accordion {
                    id
                title,
                description{
                  html
                }
                  }
              featuredImage {
                url(transformation: {image: {}, document: {}})
              }
            }
          }
          `,
        "variables": {}
    };

    const options = {
        "method": "POST",
        "headers": headers,
        "body": JSON.stringify(graphqlQuery)
    };


    const response = await fetch(endpoint, options);
    const data = await response.json();
    return data.data.projects;
}
